var menutop;

$(document).ready(function() {
    $('#bs-menu').on('shown.bs.collapse', function(e) {
        $('body').css('overflow', 'hidden');
    });

    $('#bs-menu').on('hidden.bs.collapse', function(e) {
        $('body').css('overflow', 'auto');
    });

    if (window.location.hash != '') {
        $(window).scrollTop($(window).scrollTop() + 100);
    }

    $.ajaxSetup({ cache: false });

    // SLIDER interactie
    $('.top-carrousel ul').bxSlider({
        mode: 'fade',
        pager: false,
        controls: false,
        auto: true,
        nextText: '',
        prevText: '',
        touchEnabled: true
    });

    //$(".fancybox").fancybox();

    $('.block').fitVids();

    /*
    Create dynamic BXslider
    Needed for blocks: 100
    */
    createBXslider('.bxslider');

    $('.referenties-201 ul').bxSlider({
        pager: false,
        controls: false,
        auto: true,
		minSlides: 6,
		maxSlides: 6,
		pause: 8000,
		slideWidth: 170,
		slideMargin: 30,
        touchEnabled: false
    });

    $('.quote-slider').bxSlider({
        pager: false,
        controls: true,
        auto: true,
        pause: 8000,
        touchEnabled: false
    });


    $('.partner-slider').bxSlider({
        pager: false,
        controls: true,
        auto: true,
        pause: 8000,
        minSlides: 3,
        maxSlides: 5,
        slideWidth: 170,
        slideMargin: 30,
        touchEnabled: false
    });

    /*
    Extra content toggle
    Needed for blocks: 15
    */
    $('.toggle_extra_content a').click(function(e) {
        //$(this).fadeToggle();
        e.preventDefault();
        $(this).toggleClass('up down');
        $(this).parent().parent().parent().find('.extra_content').slideToggle();
    });

    /*$('.blog-masonry').masonry({
        itemSelector: '.news-item',
        percentPosition: true
    });*/
    /*
    Order masonry style
    Needed for blocks: ... (none yet)
    */
    /*
    $('.blocklist').masonry({
        // options
        itemSelector: 'article'
        //,columnWidth: 33%
    });*/

    /*
    Gmap3 functionality
    Needed for blocks: 130, 131
    */
    $('.gmap').each(function( index ) {
        gmap_address = $(this).data('address');
        //console.log(gmap_address);
        $(this).gmap3({
            marker: {
                address: gmap_address,
                events: {
                    click: function(marker, event, data) {
                        window.open("https://maps.google.com/?daddr="+gmap_address);
                    }
                }
            },
            map:{
                options:{
                    zoom:18,
                    scrollwheel: false,
                }
            }
        });
    });

    // heights :
    if ($(window).width() > 767) {
        if ($('.partner-block-zoekwordt').length > 0) {
            var pbh = Math.max($('.partner-block-zoekwordt .zoeken form').outerHeight(), $('.partner-block-zoekwordt .worden .info').outerHeight());
            $('.partner-block-zoekwordt .zoeken figure').outerHeight(pbh);
            $('.partner-block-zoekwordt .worden .info').outerHeight(pbh);
        }
    }

    $('#nieuwsbrief').change(function() {
        window.location.href = window.base_seourl + $(this).val();
    });

    $('.gmap-partner').each(function( index ) {
        var gmap_address = $(this).data('address');
        var gmap_lat = $(this).data('lat');
        var gmap_long = $(this).data('long');
        var gmap_marker = $(this).data('marker');
        // var icon = {
        //     url: gmap_marker, // url
        //     scaledSize: new google.maps.Size(50, 50) // scaled size
        // };
        var icon = 'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2';;

        //console.log(gmap_address);
        $(this).gmap3({
            marker: {
                values:[
                    {latLng:[gmap_lat, gmap_long] , options: {icon: icon}} //- voor icon moet alles ook rond upload worden
                ],
                options:{
                    draggable: false
                },
                events: {
                    click: function(marker, event, data) {
                        window.open("https://maps.google.com/?daddr="+gmap_address);
                    }
                }
            },
            map:{
                options:{
                    zoom:12,
                    scrollwheel: false,
                }
            }
        });

    });
    $('.contact.subs').each( function() {
        var longitude = $(this).data('long');
        var latitude = $(this).data('lat');
        var gmap_address = $(this).data('address');
        var marker_icon = 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1';
        if(longitude != '' && latitude != '') {
            $('.gmap-partner').gmap3({
                marker:{
                    values:[
                        {latLng:[latitude, longitude], options: {icon: marker_icon}}
                        //{address:adres, data:text}
                    ],
                    options:{
                        draggable: false
                    },
                    events: {
                        click: function(marker, event, data) {
                            window.open("https://maps.google.com/?daddr="+gmap_address);
                        }
                    }
                }
            }, "autofit");
        }
    });

    $('a[href^="#"]').click(function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('[name="' + $.attr(this, 'href').substr(1) + '"]').offset().top - 110
        }, 500);
    });

    if ($('.partners-map').length > 0) {
        var gmap_long = 5.130615234375;
        var gmap_lat = 52.16045455774706;

        $('.partners-map').gmap3({
            map:{
                options:{
                    center: [gmap_lat, gmap_long],
                    zoom: 7,
                    scrollwheel: false,
                }
            }
        });
        $('.partner-item').each( function() {
            var longitude = $(this).data('long');
            var latitude = $(this).data('lat');

            var icon_green = 'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2';
            var icon_red = 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1';
            var icon_blue = 'https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png';

            var marker_icon = (parseInt($(this).data('partner')) > 0) ? icon_red : icon_green;
            marker_icon = (parseInt($(this).data('elektroned')) == 1) ? icon_blue : marker_icon;
            var adres = $(this).children('a').html();
            var text = $(this).children('a').html();
            var url = $(this).children('a').prop('href');
            if(longitude != '' && latitude != '') {
                $('#partners-map').gmap3({
                    marker:{
                        values:[
                            {latLng:[latitude, longitude], data:text, options: {icon: marker_icon}}
                            //{address:adres, data:text}
                        ],
                        options:{
                            draggable: false
                        },
                        events:{
                            mouseover: function(marker, event, context){
                                $(this).gmap3({
                                    infowindow:{
                                        anchor:marker,
                                        options:{content: context.data}
                                    }
                                });
                            },
                            mouseout: function(){
                                var infowindow = $(this).gmap3({get:{name:"infowindow"}});
                                if (infowindow){
                                    infowindow.close();
                                }
                            },
                            click: function(){
                                window.location = url;
                            }
                        }
                    }
                }, "autofit");
            }
        });
    }

    $('#zoek-partner-dienst').change(function(e) {
        window.location.href = $(this).val();
    });

    $('#zoek-postcode').click(function(e) {
        e.preventDefault();
        $('#postcode-text').addClass('postcode_cijfers');
        $('#zoek-trigger').val('postcode');
        $('#plaas-text').removeClass('plaats_naam');
        $('#zoek-partner').submit();
    })

    $('#zoek-plaats').click(function(e) {
        e.preventDefault();
        $('#postcode-text').removeClass('postcode_cijfers');
        $('#plaas-text').addClass('plaats_naam');
        $('#zoek-trigger').val('plaats');
        $('#zoek-partner').submit();
    })

    $("#zoek-partner").validate({
        submitHandler: function(form) {
            $.post(window.base_url +"engine/zoeken.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    window.location.href = window.base_seourl + data.url;
                } else
                {
                }
            }, 'json');
        }
    });

    /*var filterOnLoad = window.location.hash ? '.'+(window.location.hash).replace('#','') : 'all';
    $('#projecten-mix').mixItUp({
        controls: {
            enable: true
        },
        load: {
            filter: filterOnLoad,
        }
    });*/

    $('#bs-menu a[href*="#"]').click(function(e) {
        e.preventDefault();
        var parts = $(this).prop('href').split('#');
        var hash = parts[1];
        if ($('.project-filters a.filter').filter('[data-filter=".'+ hash +'"]').length > 0)
        {
            $('.project-filters a.filter').filter('[data-filter=".'+ hash +'"]').trigger('click');
        } else
        {
            window.location.href = $(this).prop('href');
        }
    });

    $('.project-fotos').bxSlider({
        pager: false,
        controls: true,
        auto: true,
        touchEnabled: false
    });


    $('a.filter').click(function() {
        window.location.hash = $(this).data('filter').replace('.', '');
    });


    $("#project-filter").keyup(function(){
        // Delay function invoked to make sure user stopped typing
        delay(function(){
            var $matching = $();
            var inputText = $('#project-filter').val().toLowerCase();

            // Check to see if input field is empty
            if ((inputText.length) > 0) {
                $( '.mix').each(function() {
                    // add item to be filtered out if input text matches items inside the title

                    if($(this).text().toLowerCase().match(inputText)) {
                        $matching = $matching.add(this);
                    }
                    else {
                        // removes any previously matched item
                        $matching = $matching.not(this);
                    }
                });
                $("#projecten-mix").mixItUp('filter', $matching);
            }

            else {
                // resets the filter to show all item if input is empty
                $("#projecten-mix").mixItUp('filter', 'all');
            }
        }, 200 );
    });

    $("#contact-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/contact.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $("#inschrijven-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/inschrijven.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $("#login-form").validate({
        submitHandler: function(form) {
            if (!$('button', form).hasClass('inloggen'))
            {
                $('button', form).addClass('inloggen');
                $('.alert-danger', form).addClass('hidden');
                $('.alert-success', form).addClass('hidden');
                $.post(window.base_url +"engine/login.php", $(form).serializeArray(), function(data){
                    if(data.succes)
                    {
                        $('.alert-success', form).removeClass('hidden');
                        window.location.href = window.location.href;
                    } else
                    {
                        $('.alert-danger', form).removeClass('hidden');
                    }
                    $('button', form).removeClass('inloggen');
                }, 'json');
            }
        }
    });

    $('#pagenav').change(function(e) {
        e.preventDefault();
        window.location.href = window.location.href.split('?')[0] + '?page=' + $(this).val();
    });

    $('.wasquote-item').click(function(e) {
        $(this).find('a').trigger('click');
    });

    equalHeights('.newslink span','.news-all');
    menutop = $('.mainmenu').offset().top;
    if ($(window).scrollTop() > menutop)
        $('.sticker').addClass('stuck');
    else
        $('.sticker').removeClass('stuck');

    $('#functie').change(function () {
       if ($(this).find('option:selected').data('toggle')) {
           $('#functie_anders_blok').removeClass('hidden');
       }  else {
           $('#functie_anders_blok').addClass('hidden');
       }
    });
});

$(document).scroll(function() {
    if ($(window).scrollTop() > $(window).height() * 0.7)
        $('body').addClass('in-scroll');
    else
        $('body').removeClass('in-scroll');

    if ($(window).scrollTop() > menutop)
        $('.sticker').addClass('stuck');
    else
        $('.sticker').removeClass('stuck');

});

$( window ).resize(function() {
    equalHeights('.newslink span','.news-all');
});

function initCoverflow(elm) {

    coverflow = new FWDSimple3DCoverflow(
        {
            //required settings
            coverflowHolderDivId: elm,
            coverflowDataListDivId:"coverflowData",
            displayType:"responsive",
            autoScale:"yes",
            coverflowWidth:940,
            coverflowHeight:560,
            skinPath:"images/coverflow/skin_white",

            //main settings
            backgroundColor:"transparent",
            backgroundImagePath:"",
            backgroundRepeat:"repeat-x",
            showDisplay2DAlways:"no",
            coverflowStartPosition:$('#'+elm).data('cf-startslide'),
            coverflowTopology:$('#'+elm).data('cf-mode'),
            coverflowXRotation:0,
            coverflowYRotation:0,
            numberOfThumbnailsToDisplayLeftAndRight:"5",
            infiniteLoop:$('#'+elm).data('cf-loop'),
            rightClickContextMenu:"default",

            //thumbnail settings
            thumbnailWidth:300,
            thumbnailHeight:500,
            thumbnailXOffset3D:86,
            thumbnailXSpace3D:78,
            thumbnailZOffset3D:200,
            thumbnailZSpace3D:93,
            thumbnailYAngle3D:0,
            thumbnailXOffset2D:20,
            thumbnailXSpace2D:30,
            thumbnailHoverOffset:100,
            thumbnailBorderSize:0,
            thumbnailBackgroundColor:"#999999",
            thumbnailBorderColor1:"#FFFFFF",
            thumbnailBorderColor2:"#000000",
            transparentImages:"yes",
            thumbnailsAlignment:"center",
            maxNumberOfThumbnailsOnMobile:13,
            showThumbnailsGradient:"yes",
            thumbnailGradientColor1:"rgba(0, 0, 0, 0)",
            thumbnailGradientColor2:"rgba(0, 0, 0, 0)",
            showText:"yes",
            textOffset:10,
            showThumbnailBoxShadow:"yes",
            thumbnailBoxShadowCss:"0 3px 4px 0 rgba(0,0,0,0.5)",
            showTooltip:"no",
            dynamicTooltip:"yes",
            showReflection:"no",
            reflectionHeight:60,
            reflectionDistance:0,
            reflectionOpacity:.4,

            //controls settings
            slideshowDelay:$('#'+elm).data('cf-pause'),
            autoplay:$('#'+elm).data('cf-autoplay'),
            disableNextAndPrevButtonsOnMobile:"no",
            controlsMaxWidth:700,
            slideshowTimerColor:"#FFFFFF",
            controlsPosition:"bottom",
            controlsOffset:15,
            showPrevButton:$('#'+elm).data('cf-controls'),
            showNextButton:$('#'+elm).data('cf-controls'),
            showSlideshowButton:$('#'+elm).data('cf-controls'),
            showScrollbar:$('#'+elm).data('cf-pager'),
            disableScrollbarOnMobile:"yes",
            enableMouseWheelScroll:"yes",
            scrollbarHandlerWidth:200,
            scrollbarTextColorNormal:"#000000",
            scrollbarTextColorSelected:"#FFFFFF",
            addKeyboardSupport:"yes",

            //categories settings
            showCategoriesMenu:"no",
            startAtCategory:1,
            categoriesMenuMaxWidth:700,
            categoriesMenuOffset:25,
            categoryColorNormal:"#999999",
            categoryColorSelected:"#FFFFFF",

            //lightbox settings
            addLightBoxKeyboardSupport:"yes",
            showLightBoxNextAndPrevButtons:"yes",
            showLightBoxZoomButton:"yes",
            showLightBoxInfoButton:"yes",
            showLightBoxSlideShowButton:"yes",
            showLightBoxInfoWindowByDefault:"no",
            slideShowAutoPlay:"no",
            lightBoxVideoAutoPlay:"no",
            lightBoxVideoWidth:640,
            lightBoxVideoHeight:480,
            lightBoxIframeWidth:800,
            lightBoxIframeHeight:600,
            lightBoxBackgroundColor:"#000000",
            lightBoxInfoWindowBackgroundColor:"#FFFFFF",
            lightBoxItemBorderColor1:"#fcfdfd",
            lightBoxItemBorderColor2:"#e4FFe4",
            lightBoxItemBackgroundColor:"#333333",
            lightBoxMainBackgroundOpacity:.8,
            lightBoxInfoWindowBackgroundOpacity:.9,
            lightBoxBorderSize:0,
            lightBoxBorderRadius:20,
            lightBoxSlideShowDelay:4000

        });
}

function createBXslider(elm){
    $(elm).each(function(index) {
        c_id = $(this).attr('id');
        //console.log(c_id);
        var options = {};
        options.mode = $(this).data('bx-mode');
        options.auto = $(this).data('bx-auto');
        options.pause = $(this).data('bx-pause');
        options.loop = $(this).data('bx-loop');
        options.pager = $(this).data('bx-pager');
        options.controls = $(this).data('bx-controls');
        options.ticker = $(this).data('bx-ticker');
        options.speed = $(this).data('bx-speed');
        options.minSlides  = $(this).data('bx-minslides') ? $(this).data('bx-minslides') : 1;
        options.maxSlides  = $(this).data('bx-maxslides') ? $(this).data('bx-maxslides') : 1;
        options.slideWidth  = $(this).data('bx-slidewidth') ? $(this).data('bx-slidewidth') : 0;

        options.nextText = '';
        options.prevText = '';
        console.log(options);
        $(this).bxSlider(options);
    });
    return;

    car_id = $(elm).attr('id');
    var cms_options = 'options_'+car_id;
    this[cms_options].value++;
    //console.log(this[cms_options]);
    var options = {};

    // auto
    if ('auto' in this[cms_options]) {
        switch(this[cms_options].auto){
            case '1':
                options.auto = true;
                break;
            default:
                options.auto = false;
        }
    }else{
        options.auto = true;
    }

    // mode
    if ('mode' in this[cms_options]) {
        switch(this[cms_options].mode){
            case '0':
                options.mode = 'fade';
                break;
            case '2':
                options.mode = 'vertical';
                break;
            case '3':
                options.mode = 'horizontal';
                options.ticker = true;
                options.speed = 8000;
                break;
            default:
                options.mode = 'horizontal';
        }
    }else{
        options.mode = 'horizontal';
    }

    //console.log(options);

    $('#'+car_id).bxSlider(options);
}

// set <img src> as a css background-image
/*function imagesToBackground(){
    $('.image-on-bg').each(function() {
        var imageUrl = $(this).find('img').first().attr('src');
        imageUrl = encodeURI(imageUrl);
        $(this).find('img').first().remove();
        $(this).css('background-image', 'url(' + imageUrl + ')');
    });
}*/

function imagesToBackground(){
    $('.image-to-bg').each(function() {
        //var imageHeight = $(this).attr('height');
        var imageUrl = $(this).attr('src');
        imageUrl = encodeURI(imageUrl);
        var parentElement = $(this).closest('div');
        parentClass = '.'+$(this).closest('section').attr('class').split(' ').pop();
        $(this).remove();
        parentElement.addClass('image-block').css('background-image', 'url(' + imageUrl + ')');
        equalHeights('.block > div', parentClass);
    });
}

function equalHeights(element, container){
    if (typeof container === 'undefined') {
        c = $(element).parent();
    } else {
        c = $(container);
    }

    var highestBox = 0;
    c.find(element).each(function(){
        if($(this).height() > highestBox){
            highestBox = $(this).height();
        }
    })
    c.find(element).height(highestBox);

}

// Delay function
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        } else {
            error.insertAfter(element);
        }
    }
});

$.validator.addClassRules({
    postcode_cijfers: {
        required: true,
        digits: true,
        minlength: 4,
        maxlength: 4
    }
});

$.validator.addClassRules({
    plaats_naam: {
        required: true,
        minlength: 3,
    }
});
